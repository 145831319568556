.dashboardBar {
  background-color: var(--color-dark-blue1);
  padding: 12px;
  border-radius: 15px;
  color: #fff;
  font-size: 0.875rem;
}
.dashboardBar li {
  padding: 0 15px;
}

.dashboardBarCaption {
  color: var(--color-dark-gray4);
  display: block;
  font-size: 0.45rem;
}

.card {
  border: 2px solid var(--color-light-gray9) !important;
  border-radius: 15px;
  color: var(--color-dark-blue1);
  margin-bottom: 30px;
}
.card .card-body {
  padding: 0;
}
.cardHeader {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.tableHeaderClass {
  color: #5B5B5B;
  font-size: 0.5rem;
  font-weight: 500;
}
.table td {
  font-size: 0.65rem !important;
  padding: 0.5rem;
}

.complianceText {
  font-size: 0.75rem;
  font-weight: 500;
}
