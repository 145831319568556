.Content {
  padding: 0;
}
.Content h1 {
  color: var(--color-dark-blue1);
  font-size: 1.625rem;
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.Content h4 {
  margin-top: 20px;
}
