.statusFlag {
  border-radius: 20px;
  display: block;
  font-size: 0.5rem;
  padding: 2px;
  text-transform: capitalize;
  width: 63px;
}
.statusFlag img {
  display: inline-block;
  margin: 0 2px 0 1px;
}
.statusFlagApproved {
  background-color: var(--color-green5);
}
.statusFlagPending {
  background-color: var(--color-dark-gray3);
}
.statusFlagRejected {
  background-color: var(--color-red2);
}
.statusFlagNewClient {
  background-color: var(--color-light-blue5);
}
